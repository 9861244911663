@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .bg-primary {
        background: #F4AB5D;
    }
    .bg-complementary {
        background: #79ABAE;
    }
    .bg-light-base {
        background: #FFFCF0;
    }
    .text-primary {
        color: #F4AB5D;
    }
    .text-complementary {
        color: #79ABAE;
    }
    .text-black {
        color: black;
    }
    .text-333 {
        color: #333333;
    }
    .text-555 {
        color: #555555;
    }
    .text-888 {
        color: #888888;
    }
    .text-white {
        color: white;
    }
    .font-heading, h1, h2, h3, h4, h5, h6 {
        font-family: 'Nerko One', cursive;
        margin: 0 0 1.8rem 0;
    }
    .font-title {
        font-family: 'Nerko One', cursive;
    }
    .font-text {
        font-family: 'Itim', cursive;
    }
    p {
        margin: 0 0 1.7rem 0;
    }
}

body {
    font-family: 'Itim', cursive;
}
